exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-category-sanity-product-category-slug-current-tsx": () => import("./../../../src/pages/products/category/{sanityProductCategory.slug__current}.tsx" /* webpackChunkName: "component---src-pages-products-category-sanity-product-category-slug-current-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-sanity-product-slug-current-tsx": () => import("./../../../src/pages/products/{sanityProduct.slug__current}.tsx" /* webpackChunkName: "component---src-pages-products-sanity-product-slug-current-tsx" */),
  "component---src-pages-sanity-about-page-slug-current-tsx": () => import("./../../../src/pages/{sanityAboutPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-about-page-slug-current-tsx" */),
  "component---src-pages-sanity-page-slug-current-tsx": () => import("./../../../src/pages/{sanityPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-tsx" */),
  "component---src-pages-sanity-press-page-slug-current-tsx": () => import("./../../../src/pages/{sanityPressPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-press-page-slug-current-tsx" */),
  "component---src-pages-sanity-suppliers-page-slug-current-tsx": () => import("./../../../src/pages/{sanitySuppliersPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-suppliers-page-slug-current-tsx" */),
  "slice---src-slices-footer-footer-tsx": () => import("./../../../src/slices/Footer/Footer.tsx" /* webpackChunkName: "slice---src-slices-footer-footer-tsx" */),
  "slice---src-slices-site-header-site-header-tsx": () => import("./../../../src/slices/SiteHeader/SiteHeader.tsx" /* webpackChunkName: "slice---src-slices-site-header-site-header-tsx" */)
}

